import React, { useState, useEffect } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

import "./SideMenu.css";

const SideMenu = ({ setSideMenuVisible, postTitle, parentSlug }) => {
  const { menuQuery, activeMenuQuery } = useStaticQuery(graphql`
    query {
      menuQuery: allContentfulPrimaryMenuGroup {
        edges {
          node {
            menuTitle
            menuItems {
              ... on ContentfulSubMenu {
                id
                __typename
                menuTitle
                allPages {
                  id
                  __typename
                  title
                  slug
                }
              }
              ... on ContentfulPostPage {
                id
                __typename
                title
                slug
              }
            }
          }
        }
      }
      activeMenuQuery: contentfulSiteGroup {
        mainGroupItems {
          ... on ContentfulPrimaryMenuGroup {
            __typename
            menuTitle
          }
          ... on ContentfulPostPage {
            __typename
            title
          }
        }
      }
    }
  `);

  const allMenus = menuQuery.edges.map((edge) => edge.node);
  const activeMenuItems = activeMenuQuery.mainGroupItems
    .filter((menuItem) => menuItem.__typename === "ContentfulPrimaryMenuGroup")
    .map((menuItem) => menuItem.menuTitle);

  const [siteGroupName, setSiteGroupName] = useState("");
  const [subMenuGroup, setSubMenuGroup] = useState(null);
  const [isNestedMenu, setIsNestedMenu] = useState(false);

  useEffect(() => {
    const [matchingSiteGroup] = allMenus.filter((menu) => {
      return menu.menuItems.find((menuItem) => {
        if (menuItem.allPages) {
          return menuItem.allPages.find((page) => page.title === postTitle);
        }
        return menuItem.title === postTitle;
      });
    });
    if (!matchingSiteGroup || !activeMenuItems.includes(matchingSiteGroup.menuTitle)) {
      setSideMenuVisible(false);
      return;
    }
    if (matchingSiteGroup) {
      setSiteGroupName(matchingSiteGroup.menuTitle);
      setSubMenuGroup(matchingSiteGroup.menuItems);
      const [nestedSubMenu] = matchingSiteGroup.menuItems.filter((menuItem) => {
        if (menuItem.allPages) {
          return menuItem.allPages.find((page) => page.title === postTitle);
        }
        return false;
      });
      if (nestedSubMenu) {
        setIsNestedMenu(true);
        setSubMenuGroup(nestedSubMenu);
      }
    }
    if (!matchingSiteGroup) {
      setSideMenuVisible(false);
    }
  }, [allMenus, setSideMenuVisible, postTitle]);

  const sluggify = (string) => {
    return string
      .toLowerCase()
      .replace(/[^\w ]+/g, "")
      .replace(/\s+/g, "-");
  };

  return (
    <aside className={`side-menu ${isNestedMenu ? "" : "no-sub-menu"}`}>
      <p className="primary-menu-title">
        <Link to={`/${sluggify(siteGroupName.trim())}/`}>{siteGroupName}</Link>
      </p>
      {isNestedMenu ? (
        <>
          <p className="sub-menu-title">{subMenuGroup.menuTitle}</p>
          <nav className="side-nav">
            {subMenuGroup && (
              <ul>
                {subMenuGroup.allPages.map((page) => (
                  <li key={page.id} className="sub-menu-link">
                    {page.slug === parentSlug ? (
                      <p className="current-page">{page.title}</p>
                    ) : (
                      <Link to={`/post/${page.slug}/`}>{page.title}</Link>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </nav>
        </>
      ) : (
        <nav>
          {subMenuGroup && (
            <ul>
              {subMenuGroup.map((item) => {
                const pageTitle = item.title || item.menuTitle;
                const pageLink = item.slug || item.allPages[0].slug;
                return (
                  <li key={item.id} className="sub-menu-link">
                    {item.slug === parentSlug ? (
                      <p className="current-page">{item.title}</p>
                    ) : (
                      <Link to={`/post/${pageLink}/`}>{pageTitle}</Link>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </nav>
      )}
    </aside>
  );
};

export default SideMenu;

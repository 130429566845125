import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SideMenu from "../components/SideMenu/SideMenu"
import RichTextContent from "../components/RichTextContent/RichTextContent"

import pinkBirdRight from "../images/pink-bird-right.svg"
import "./pagepost.css"

const PagePost = ({ data, pageContext }) => {
  const { title, content, postColor } =
    data.contentfulPostPage
  const { slug } = pageContext

  const [sideMenuVisible, setSideMenuVisible] = useState(true);

  return (
    <Layout>
      <Seo title={title} keywords={[`queer`, `care`, `stress`, "lgbtq2"]} />
      <section className={`content ${postColor}`}>
        <div className={`content-grid ${sideMenuVisible ? "" : "no-side-menu"}`}>
          {sideMenuVisible && (
            <div className="sticky">
              <SideMenu
                postTitle={title}
                parentSlug={slug}
                setSideMenuVisible={setSideMenuVisible}
              />
            </div>
          )}
          <div className={`history ${slug === "videos" ? "videos-page" : ""}`}>
            <h1>{title}</h1>

            {data.contentfulPostPage.content && (
              <RichTextContent content={content} />
            )}
            <div className="bird-border">
              <img src={pinkBirdRight} alt="" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PagePost

export const pageQuery = graphql`
  query ($slug: String!) {
    contentfulPostPage(slug: { eq: $slug }) {
      id
      title
      slug
      postColor
      content {
        raw
        references {
          ... on ContentfulDefinition {
            contentful_id
            __typename
            definingWord
            definition {
              raw
            }
          }
          ... on ContentfulColorBlockedText {
            contentful_id
            __typename
            content {
              raw
            }
          }
          ... on ContentfulTable {
            contentful_id
            __typename
            title
            table {
              tableData
            }
          }
          ... on ContentfulDetailedTable {
            contentful_id
            __typename
            title
            tableContents {
              raw
              references {
                ... on ContentfulDefinition {
                  contentful_id
                  __typename
                  definingWord
                  definition {
                    raw
                  }
                }
              }
            }
          }
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
            description
          }
          ... on ContentfulVideo {
            contentful_id
            __typename
            title
            videoFile {
              file {
                url
              }
            }
            thumbnail {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
